/* DIALOGS */
ion-alert {
  .alert-wrapper {
    .alert-head {
      background-color: var(--ion-color-primary);

      h2 {
        font-weight: 300;
        font-size: 20px;
        text-align: center;
        color: var(--ion-color-primary-contrast);
        margin-top: 6px;
        margin-bottom: 6px;
      }
    }

    .alert-button-group {
      border-top: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, .13))));

      .alert-button {

        span {
          color: var(--ion-color-primary);
        }

        &.alert-button-logout,
        &.cancel-button {

          span {
            color: var(--ion-color-medium-shade) !important;
          }
        }
      }
    }

    .alert-radio-group {

      .alert-radio-button {
        height: auto;
        contain: content;

        .alert-button-inner {
          .alert-radio-label {
            white-space: normal;
          }
        }
      }
    }

    .alert-message {
      padding-top: 24px !important;
      font-size: 14px;
      background: var(--ion-color-primary-contrast);
      color: #000;

      &:empty {
        padding: 0px !important;
      }
    }

  }

  &.error-dialog {
    .alert-wrapper {
      .alert-head {
        background-color: var(--ion-color-danger);
      }

      .alert-button-group {
        .alert-button {
          span {
            color: var(--ion-color-danger);
          }
        }
      }
    }
  }

  &.success-dialog {
    .alert-wrapper {
      .alert-head {
        background-color: var(--ion-color-success);
      }

      .alert-button-group {
        .alert-button {
          span {
            color: var(--ion-color-success);
          }
        }
      }
    }
  }

  &.warning-dialog,
  &.confirm-warning-dialog {
    .alert-wrapper {
      .alert-head {
        background-color: var(--ion-color-warning);
      }

      .alert-button-group {
        .alert-button {
          span {
            color: var(--ion-color-warning);
          }
        }
      }
    }
  }

  &.confirm-warning-dialog {
    .alert-wrapper {

      .alert-button-group {
        flex-direction: row;
      }
    }
  }

}

.provider-marker {
  i {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.order-marker {
  span {
    &.ON_THE_WAY_TO_CUSTOMER {
      color: forestgreen;
    }

    &.READY_FOR_HAULAGE {
      color: var(--ion-color-primary);
    }

    &.ON_THE_WAY_TO_PROVIDER {
      color: cornflowerblue;
    }

    &.URGENT_ORDER {
      color: #D9534F;
    }

    &.WITH_TIME_CONSTRAINT {
      color: orange;
    }
  }
}

.order-sequence-text {
  font-size: 1em;
  line-height: 1.8em;
  padding-bottom: 6px;
}

/* DATETIME */
ion-item {
  &.date-time-container {
    &::part(native) {
      padding-inline-start: 0;
    }

    ion-label {
      padding-left: 16px;
    }
  }
}

ion-modal {
  &.datetime-modal {
    --width: 290px;
    --height: 395px;
    --border-radius: 8px;
    --backdrop-opacity: 0.32 !important;

    ion-datetime {
      height: auto;
      width: auto;

      max-width: 350px;
    }

    &.time-only-picker {
      --height: 265px;

      ion-datetime {
        margin-top: 12px;
      }
    }
  }
}

// NOTE: The [hidden] attribute has been removed from Ionic's global stylesheet
// see https://github.com/ionic-team/ionic-framework/blob/main/BREAKING.md#hidden-attribute
[hidden] {
  display: none !important;
}
